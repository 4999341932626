<template>
  <div>
    <Lock />
  </div> 
</template>

<script>

  import Lock from "../../components/auth/Lock"


  export default {
    components: {
      Lock
    },
   
    setup(props) {

      //return { isWorkerLogged, operationId }
    }
  
  }
</script>
